import React from "react"
import { Link } from "gatsby"
// import {FaInstagram, FaLinkedin} from "react-icons/fa";
// import BMC from "../assets/BMC.svg"

const globalFooter = React.forwardRef((props, ref) => {
  const block = "globalFooter"

  return (
    <footer className={`${block}`}>
      <p>
        Made with{" "}
        <span role='img' aria-label='heart'>
          ❤️
        </span>{" "}
        in Istanbul.
      </p>
      <p>
        <Link to='/about'>About</Link>
        {" / "}
        <Link to='/privacy'>Privacy Policy</Link>
      </p>

    </footer>
  )
})

export default globalFooter
